<template>
  <div v-if="!isLoading" class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder">
          {{ $t("Employee information") }}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">
          {{ $t("Update employment information") }}
        </span>
      </div>
      <div v-if="isAuthorized" class="card-toolbar">
        <button v-if="employee.is_active" class="btn btn-light-primary font-weight-bolder mr-2" @click="editContract()">
          <span class="svg-icon svg-icon-md">
            <inline-svg src="/media/svg/icons/Design/Edit.svg" />
          </span>
          {{ $t("Edit contract") }}
        </button>
        <button v-if="employee.is_active" class="btn btn-light-danger font-weight-bolder mr-2"
          :class="{ 'spinner spinner-light spinner-right': isSaving }" @click.prevent="terminateContract">
          <span class="svg-icon svg-icon-md">
            <inline-svg src="/media/svg/icons/Code/Error-circle.svg" />
          </span>
          {{ $t("Terminate contract") }}
        </button>
        <router-link v-else class="btn btn-light-primary font-weight-bolder mr-2"
          :to="{ name: 'associate-contract', params: { ppId: physicalPerson.id }, query: { redirect: this.$route.fullPath } }">
          <span class="svg-icon svg-icon-md">
            <inline-svg src="/media/svg/icons/Code/Plus.svg" />
          </span>
          {{ $t("Add New Contract") }}
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!isAuthorized" class="alert alert-custom alert-outline-warning fade show mb-5" role="alert">
        <div class="alert-icon"><i class="fas fa-user-lock"></i></div>
        <div class="alert-text">{{ $t("You are not authorized to view this information") }}</div>
      </div>
      <div v-else-if="employee.is_active === false" class="alert alert-custom alert-light mb-5">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{ $t("This employee has no currently active contract") }}</div>
      </div>
      <div v-else-if="!employee">
        {{ $t("No employee information") }}
      </div>
      <div v-else-if="lastContract">
        <div class="d-flex align-items-center mb-6">
          <div class="font-weight-bold font-size-h4">{{ lastContract.national_contract.name }}</div>
          <span class="label label-lg label-inline label-light-success font-weight-bolder ml-4">{{
            lastContract.national_contract_level.level_name }}</span>
        </div>
        <div class="d-flex align-items-center mb-2">
          <div class="font-size-lg font-weight-bold">{{ $t("Start date") }}:</div>
          <div class="ml-3">{{ formattedDate(lastContract.start_date, dateFormat) }}</div>
          <div v-if="lastContract.end_date" class="ml-3 font-size-lg font-weight-bold">{{ $t("End date") }}:</div>
          <div v-if="lastContract.end_date" class="ml-3">{{ formattedDate(lastContract.end_date, dateFormat) }}</div>
        </div>
        <div v-if="employee.end_date" class="d-flex align-items-center mb-2">
          <div class="ml-3 font-size-lg font-weight-bold">{{ $t("End date") }}:</div>
          <div class="ml-3">{{ formattedDate(lastContract.end_date, dateFormat) }}</div>
        </div>
        <div class="d-flex align-items-center">
          <div class="font-size-lg font-weight-bold">{{ $t("Main Work Location") }}:</div>
          <div class="ml-3">{{ lastContract.employeecontractworklocation_set[0].work_location.formatted }}</div>
          <span v-if="lastContract.employeecontractworklocation_set[0].remote_work"
            class="label label-inline label-light-success font-weight-bolder ml-4">{{ $t('Remote Work') }}</span>
        </div>

        <div class="separator separator-dashed my-8"></div>

        <div class="font-size-h5 font-weight-bold mb-4 mt-2">{{ $t("Details") }}</div>
        <b-table :items="contractItems" thead-class="d-none"></b-table>
      </div>
      <!-- past contracts table-->
      <div v-if="isAuthorized && pastContracts?.length" class="row mt-3">
        <div class="col-12">
          <div id="accordionPastContractsDetails" class="accordion accordion-solid accordion-toggle-arrow">
            <div class="card">
              <div id="headingPastContractsDetails" class="card-header">
                <div :class="['card-title', { collapsed: !showCollapsePastContracts }]"
                  @click="showCollapsePastContracts = !showCollapsePastContracts">
                  <i class="far fa-folder"></i> {{ $t("Past Contracts") }}
                </div>
              </div>
              <b-collapse id="collapsePastContractDetails" v-model="showCollapsePastContracts">
                <div class="card-body">
                  <b-table :items="pastContractsItems" :fields="pastContractsFields">
                    <template v-slot:cell(start_date)="data">
                      {{ formattedDate(data.item.start_date, dateFormat) }}
                    </template>
                    <template v-slot:cell(end_date)="data">
                      {{ formattedDate(data.item.end_date, dateFormat) }}
                    </template>
                    <template v-slot:cell(actions)="data">
                      <b-button v-b-tooltip.hover :title="employee.is_active ? $t('View') : $t('Edit')"
                        class="btn btn-icon btn-light btn-hover-primary btn-sm" @click="editContract(data.item.id)">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                        </span>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAuthorized && !lastContract && pastContracts?.length == 0" class="alert alert-custom alert-light">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{ $t("No contracts") }}</div>
      </div>
      <!-- past contracts table end-->
    </div>
    <associate-contract-modal :existing-form="editForm" :physical-person="physicalPerson" :read-only="computedReadOnly"
      @refresh="loadEmployee">
    </associate-contract-modal>
    <!-- Termination Modal -->
    <b-modal ref="terminationModal" hide-footer @hidden="resetModal" @ok="confirmTerminate">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center py-1">
          <h3 class="mt-3">{{ $t("Terminate Contract") }}</h3>
          <div class="d-flex align-items-center">
            <b-button variant="secondary" class="mr-2" @click="$refs.terminationModal.hide()">{{ $t("Cancel")
            }}</b-button>
            <b-button variant="primary" @click="confirmTerminate()">{{ $t("Confirm") }}</b-button>
          </div>
        </div>
      </template>
      <date-picker id="termination-date" v-model="terminationDate"
        :label="$t('Select employment contract termination date')"
        :overrides="fromDateOverrides(lastContract?.start_date)" />
    </b-modal>
  </div>
  <div v-else class="text-center">
    <div class="card card-custom">
      <div class="card-body p-30">
        <div class="d-flex justify-content-center align-items-center">
          <b-spinner class="mr-5" variant="primary"></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import EmployeeService from "@/core/services/employee/employee.service";
import AssociateContractModal from "@/view/components/modals/physical-person/AssociateContractModal.vue";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import moment from "moment";
import { successToast } from '@/core/helpers';

export default {
  components: { AssociateContractModal, DatePicker },
  props: {
    physicalPerson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employee: null,
      isSaving: false,
      showCollapsePastContracts: false,
      isLoading: true,
      contractAuthorization: false,
      editForm: {},
      terminationDate: new Date().toISOString().split('T')[0],
      pastContractsItems: null,
      pastContractsFields: [
        { key: 'national_contract', label: this.$t('National Contract') },
        { key: 'national_contract_level', label: this.$t('Level') },
        { key: 'start_date', label: this.$t('Start Date') },
        { key: 'end_date', label: this.$t('End Date') },
        { key: 'actions', label: this.$t('Actions') }
      ],
      editingLast: false,
      DateService
    }
  },
  computed: {
    ...mapGetters("user", ["dateFormat", "isAfterSale", "getCurrentManagedCompany"]),
    lastContract() {
      if (this.employee.is_active && this.employee.employeecontract_set) {
        return this.employee.employeecontract_set[0];
      }
      return null;
    },
    pastContracts() {
      if (this.employee.is_active && this.employee?.employeecontract_set) {
        return this.employee.employeecontract_set.slice(1);
      }
      return this.employee?.employeecontract_set;
    },
    contractItems() {
      if (!this.lastContract) {
        return [];
      }
      let tableData = [
        { label: this.$t("Average hourly pay"), value: this.lastContract.average_hourly_pay || '0' + " " + this.lastContract.currency.symbol },
        { label: this.$t("Max hourly pay"), value: this.lastContract.max_hourly_pay || '0' + " " + this.lastContract.currency.symbol },
        { label: this.$t("Seniority level"), value: this.lastContract.seniority_level },
        { label: this.$t("Employed for"), value: this.lastContract.days_employed + " " + this.$t("days") },
        { label: this.$t("Daily posting compensation"), value: this.lastContract.daily_posting_compensation + " " + this.lastContract.currency.symbol },
        { label: this.$t("Monthly Superminimum"), value: this.lastContract.monthly_superminimum + " " + this.lastContract.currency.symbol },
        { label: this.$t("Total legal salary"), value: this.lastContract.total_legal_salary + " " + this.lastContract.currency.symbol },
        { label: this.$t("Total monthly salary"), value: this.lastContract.total_monthly_salary + " " + this.lastContract.currency.symbol },
        { label: this.$t("Monthly salary with TFR"), value: this.lastContract.monthly_salary_with_tfr || '0' + " " + this.lastContract.currency.symbol },
        { label: this.$t("Yearly salary (no TFR)"), value: this.lastContract.total_yearly_salary_no_tfr || '0' + " " + this.lastContract.currency.symbol },
      ]
      let bonusData = this.getBonuses();
      return [...tableData, ...bonusData];
    },
    computedReadOnly() {
      return !this.editingLast ? this.employee.is_active : false
    },
    isAuthorized() {
      if (this.isAfterSale) {
        return false;
      }
      if (this.getCurrentManagedCompany?.contract_permission) {
        return true;
      }
      return this.contractAuthorization;
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person"), route: { name: "manage-physical-person" } },
      { title: this.$t("Employee Details") },
    ]);
    await this.loadEmployee();
  },
  methods: {
    moment,
    async loadEmployee() {
      this.isLoading = true;
      await EmployeeService.getOne(this.physicalPerson.employee_id, "", "employeecontract_set,employeecontract_set.national_contract_level").then(res => {
        this.employee = res.data;
        this.pastContractsItems = this.getPastContractItems();
        this.contractAuthorization = res.data.contract_authorization;
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.isLoading = false;
      })
      // try {
      //   this.employee = (await EmployeeService.getOne(this.physicalPerson.employee_id, "", "employeecontract_set,employeecontract_set.national_contract_level")).data;
      //   this.pastContractsItems = this.getPastContractItems();
      //   this.isLoading = false;
      // } catch (e) {
      //   console.error(e);

      // }
    },
    getPastContractItems() {
      // table elements one row for each pastContract in pastContracts with contract name, level, start and end date and actions to view details
      return this.pastContracts?.map(contract => {
        return {
          id: contract.id,
          national_contract: contract.national_contract.name,
          national_contract_level: contract.national_contract_level.level_name,
          start_date: contract.start_date,
          end_date: contract.end_date,
          actions: [
            {
              text: this.$t("View details"),
              variant: "primary",
              onClick: () => {
                this.$router.push({ name: "employee-contract-details", params: { id: contract.id } });
              }
            }
          ]
        }
      })

    },
    async editContract(id = null) {
      this.editingLast = !id;
      this.editForm = {
        "id": id ? id : this.lastContract.id,
      };
      this.$bvModal.show("associate-contract-modal");
    },
    async terminateContract() {
      this.$refs.terminationModal.show();
    },
    resetModal() {
      this.terminationDate = null;
    },
    async confirmTerminate() {
      if (!this.terminationDate) return;

      try {
        await EmployeeService.terminateEmploymentContract(this.lastContract.id, this.terminationDate);
        this.$refs.terminationModal.hide();
        successToast(this.$t("Employment contract terminated successfully"));
        await this.loadEmployee();
      } catch (error) {
        console.error(error);
        await backendErrorSwal(error);
      }
    },
    getBonuses() {
      return this.lastContract.employeecontractbonus_set.map(bonus => {
        let validToLabel = bonus.bonus_valid_to ? (" to: " + bonus.bonus_valid_to) : "";
        return {
          label: this.$t("Bonus") + " valid From: " + bonus.bonus_valid_from + validToLabel,
          value: bonus.bonus + " " + this.lastContract.currency.symbol
        }
      })
    },
    formattedDate(date, format) {
      if (!date) return "";
      return DateService.format(date, format);
    },
    fromDateOverrides(date) {
      return { minDate: moment(date) };
    }
  }
}
</script>