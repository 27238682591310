var render = function render(){
  var _vm$pastContracts, _vm$pastContracts2, _vm$lastContract;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Employee information")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("Update employment information")) + " ")])]), _vm.isAuthorized ? _c('div', {
    staticClass: "card-toolbar"
  }, [_vm.employee.is_active ? _c('button', {
    staticClass: "btn btn-light-primary font-weight-bolder mr-2",
    on: {
      "click": function click($event) {
        return _vm.editContract();
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Design/Edit.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Edit contract")) + " ")]) : _vm._e(), _vm.employee.is_active ? _c('button', {
    staticClass: "btn btn-light-danger font-weight-bolder mr-2",
    class: {
      'spinner spinner-light spinner-right': _vm.isSaving
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.terminateContract.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Code/Error-circle.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Terminate contract")) + " ")]) : _c('router-link', {
    staticClass: "btn btn-light-primary font-weight-bolder mr-2",
    attrs: {
      "to": {
        name: 'associate-contract',
        params: {
          ppId: _vm.physicalPerson.id
        },
        query: {
          redirect: this.$route.fullPath
        }
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Code/Plus.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add New Contract")) + " ")])], 1) : _vm._e()]), _c('div', {
    staticClass: "card-body"
  }, [!_vm.isAuthorized ? _c('div', {
    staticClass: "alert alert-custom alert-outline-warning fade show mb-5",
    attrs: {
      "role": "alert"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "alert-text"
  }, [_vm._v(_vm._s(_vm.$t("You are not authorized to view this information")))])]) : _vm.employee.is_active === false ? _c('div', {
    staticClass: "alert alert-custom alert-light mb-5"
  }, [_vm._m(1), _c('div', {
    staticClass: "alert-text"
  }, [_vm._v(_vm._s(_vm.$t("This employee has no currently active contract")))])]) : !_vm.employee ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("No employee information")) + " ")]) : _vm.lastContract ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center mb-6"
  }, [_c('div', {
    staticClass: "font-weight-bold font-size-h4"
  }, [_vm._v(_vm._s(_vm.lastContract.national_contract.name))]), _c('span', {
    staticClass: "label label-lg label-inline label-light-success font-weight-bolder ml-4"
  }, [_vm._v(_vm._s(_vm.lastContract.national_contract_level.level_name))])]), _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('div', {
    staticClass: "font-size-lg font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Start date")) + ":")]), _c('div', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.formattedDate(_vm.lastContract.start_date, _vm.dateFormat)))]), _vm.lastContract.end_date ? _c('div', {
    staticClass: "ml-3 font-size-lg font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("End date")) + ":")]) : _vm._e(), _vm.lastContract.end_date ? _c('div', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.formattedDate(_vm.lastContract.end_date, _vm.dateFormat)))]) : _vm._e()]), _vm.employee.end_date ? _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('div', {
    staticClass: "ml-3 font-size-lg font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("End date")) + ":")]), _c('div', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.formattedDate(_vm.lastContract.end_date, _vm.dateFormat)))])]) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "font-size-lg font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Main Work Location")) + ":")]), _c('div', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.lastContract.employeecontractworklocation_set[0].work_location.formatted))]), _vm.lastContract.employeecontractworklocation_set[0].remote_work ? _c('span', {
    staticClass: "label label-inline label-light-success font-weight-bolder ml-4"
  }, [_vm._v(_vm._s(_vm.$t('Remote Work')))]) : _vm._e()]), _c('div', {
    staticClass: "separator separator-dashed my-8"
  }), _c('div', {
    staticClass: "font-size-h5 font-weight-bold mb-4 mt-2"
  }, [_vm._v(_vm._s(_vm.$t("Details")))]), _c('b-table', {
    attrs: {
      "items": _vm.contractItems,
      "thead-class": "d-none"
    }
  })], 1) : _vm._e(), _vm.isAuthorized && (_vm$pastContracts = _vm.pastContracts) !== null && _vm$pastContracts !== void 0 && _vm$pastContracts.length ? _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "accordion accordion-solid accordion-toggle-arrow",
    attrs: {
      "id": "accordionPastContractsDetails"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "headingPastContractsDetails"
    }
  }, [_c('div', {
    class: ['card-title', {
      collapsed: !_vm.showCollapsePastContracts
    }],
    on: {
      "click": function click($event) {
        _vm.showCollapsePastContracts = !_vm.showCollapsePastContracts;
      }
    }
  }, [_c('i', {
    staticClass: "far fa-folder"
  }), _vm._v(" " + _vm._s(_vm.$t("Past Contracts")) + " ")])]), _c('b-collapse', {
    attrs: {
      "id": "collapsePastContractDetails"
    },
    model: {
      value: _vm.showCollapsePastContracts,
      callback: function callback($$v) {
        _vm.showCollapsePastContracts = $$v;
      },
      expression: "showCollapsePastContracts"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.pastContractsItems,
      "fields": _vm.pastContractsFields
    },
    scopedSlots: _vm._u([{
      key: "cell(start_date)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formattedDate(data.item.start_date, _vm.dateFormat)) + " ")];
      }
    }, {
      key: "cell(end_date)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formattedDate(data.item.end_date, _vm.dateFormat)) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.employee.is_active ? _vm.$t('View') : _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.editContract(data.item.id);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)])];
      }
    }], null, false, 2582769169)
  })], 1)])], 1)])])]) : _vm._e(), _vm.isAuthorized && !_vm.lastContract && ((_vm$pastContracts2 = _vm.pastContracts) === null || _vm$pastContracts2 === void 0 ? void 0 : _vm$pastContracts2.length) == 0 ? _c('div', {
    staticClass: "alert alert-custom alert-light"
  }, [_vm._m(2), _c('div', {
    staticClass: "alert-text"
  }, [_vm._v(_vm._s(_vm.$t("No contracts")))])]) : _vm._e()]), _c('associate-contract-modal', {
    attrs: {
      "existing-form": _vm.editForm,
      "physical-person": _vm.physicalPerson,
      "read-only": _vm.computedReadOnly
    },
    on: {
      "refresh": _vm.loadEmployee
    }
  }), _c('b-modal', {
    ref: "terminationModal",
    attrs: {
      "hide-footer": ""
    },
    on: {
      "hidden": _vm.resetModal,
      "ok": _vm.confirmTerminate
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center py-1"
        }, [_c('h3', {
          staticClass: "mt-3"
        }, [_vm._v(_vm._s(_vm.$t("Terminate Contract")))]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.terminationModal.hide();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.confirmTerminate();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Confirm")))])], 1)])];
      },
      proxy: true
    }], null, false, 751312084)
  }, [_c('date-picker', {
    attrs: {
      "id": "termination-date",
      "label": _vm.$t('Select employment contract termination date'),
      "overrides": _vm.fromDateOverrides((_vm$lastContract = _vm.lastContract) === null || _vm$lastContract === void 0 ? void 0 : _vm$lastContract.start_date)
    },
    model: {
      value: _vm.terminationDate,
      callback: function callback($$v) {
        _vm.terminationDate = $$v;
      },
      expression: "terminationDate"
    }
  })], 1)], 1) : _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body p-30"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-spinner', {
    staticClass: "mr-5",
    attrs: {
      "variant": "primary"
    }
  })], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "fas fa-user-lock"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon-warning"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon-warning"
  })]);

}]

export { render, staticRenderFns }